<template>
  <!--begin::Modal - Create Api Key-->
  <!--
      class="modal fade show" id="otp_modal" tabindex="-1" aria-modal="true" role="dialog" style="display: block;">
  -->
  <div
    class="modal fade"
    id="otp_modal"
    ref="createAPIKeyModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-550px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>{{ $t("verifyOTP.title") }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Form-->
        <Form
          id="kt_modal_create_api_key_form"
          class="form"
          @submit.prevent="submit"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal body-->
          <div class="modal-body py-6 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="me-n7 pe-7"
              id="kt_modal_create_api_key_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <div
                class="
                  notice
                  d-flex
                  bg-light-warning
                  rounded
                  border-warning border border-dashed
                  mb-10
                  p-6
                "
              >
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1">
                  <div class="fs-6 fw-bold">
                    {{ $t("verifyOTP.desc") }}
                  </div>
                </div>
                <!--end::Wrapper-->
              </div>

              <div
                v-if="countdownTimer"
                class="fs-4 mt-2 fw-bold pb-4"
                style="text-align: center"
              >
                Kalan süre: {{ countdownTimer }}
              </div>

              <!--begin::Input group-->
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">{{
                  $t("verifyOTP.code")
                }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  :placeholder="$t('verifyOTP.title')"
                  name="verifyCode"
                  v-model="verifyOTPModel.otp"
                  v-mask="'#-#-#-#-#-#'"
                  @input="checkOTP"
                  @keydown.enter.prevent
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="verifyCode" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div
            class="modal-footer flex-center"
            style="border-top: 0px; padding-top: 5px"
          >
            <!--begin::Button-->
            <button
              ref="submitButtonRef"
              type="submit"
              id="kt_modal_create_api_key_submit"
              class="btn btn-primary"
              :disabled="submitting"
            >
              <span class="indicator-label">{{
                $t("common.button.verify")
              }}</span>
              <span class="indicator-progress">
                {{ $t("common.pleaseWait") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create Api Key-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "../helper/Dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useStore } from "vuex";
import { VerifyOTPModel } from "@/domain/auth/verify-otp/model/VerifyOTPModel";
import SwalNotification from "../plugins/SwalNotification";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { Actions } from "../store/enums/StoreEnums";
import { debounce } from "lodash";

export default defineComponent({
  name: "otp-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const otpController = store.state.ControllersModule.otpController;
    const countdownTimer = ref();
    const remainingAttempts = ref();
    const verifyOTPModel = ref<VerifyOTPModel>({});
    const jwtTokenManager = new JwtTokenManager();

    let timer: any;
    let submitting = ref(false);

    let token: undefined | string = undefined;

    const validationSchema = Yup.object().shape({
      verifyCode: Yup.string()
        .min(11, t("validators_error.codeInvalid"))
        .label("verifyCode"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitting.value = true;
      submitButtonRef.value.disabled = true;
      // Activate indicator

      remainingAttempts.value = Number(store.getters.remainingAttempts);

      verifyOTPModel.value.phoneNumber = store.getters.phoneNumber;
      verifyOTPModel.value.countryCode = store.getters.countryCode;
      verifyOTPModel.value.otp = String(verifyOTPModel.value.otp).replace(
        /\D/g,
        ""
      );

      if (submitButtonRef.value && remainingAttempts.value > 0) {
        submitButtonRef.value.disabled = false;

        otpController
          .verifyOTP(verifyOTPModel.value)
          .then((response) => {
            if (response.isSuccess) {
              clearInterval(timer);
              store.dispatch("expireTime", "");
              store.dispatch(Actions.VERIFY_AUTH);

              submitButtonRef.value?.removeAttribute("data-kt-indicator");

              hideModal(createAPIKeyModalRef.value);
              if (
                response.getValue().name == null ||
                response.getValue().surname == null
              ) {
                router.push({ name: "setProfileInfo", query: { token: token } });
              } 
              else if(token) {
                router.push({ name: ROUTE_PAGES.ACCEPT_INVITE, query: { token: token } });
              }
              else {
                router.push({ name: ROUTE_PAGES.DASHBOARD });
              }
              /*
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_VERIFY),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                heightAuto: false,
              })
              */
            } else {
              remainingAttempts.value = remainingAttempts.value - 1;
              store.dispatch("remainingAttempts", remainingAttempts.value);

              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          })
          .finally(() => {
            submitting.value = false; 
          });
      } else {
        Swal.fire({
          text: t(SWAL_MESSAGES.ERROR.ERROR_VERIFY_REMAINING_ATTEMPTS),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
          heightAuto: false,
        }).then(() => {
          hideModal(createAPIKeyModalRef.value);
          router.push({ name: ROUTE_PAGES.SIGN_IN });
          window.location.reload();
        });
      }
    };

    const checkOTP = debounce(() => {
      if (verifyOTPModel.value.otp && verifyOTPModel.value.otp.length === 11) {
        submit();
        verifyOTPModel.value.otp = ""
      }
    }, 300);

    watch(
      () => store.getters.expireTime,
      (newExpireTime) => {
        if (newExpireTime != "") startCountdown(newExpireTime);
      }
    );

    let alertShown = false; // Flag to track if the alert has been shown

    const startCountdown = (expireTime) => {
      clearInterval(timer);

      if (store.getters.expireTime != "") {
        const now = new Date().getTime();
        const expireDateTime = new Date(expireTime).getTime();
        const distance = expireDateTime - now;
        if (distance > 0) {
          timer = setInterval(() => {
            const now = new Date().getTime();
            const distance = expireDateTime - now;

            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            )
              .toString()
              .padStart(2, "0");
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)
              .toString()
              .padStart(2, "0");

            countdownTimer.value = `${minutes}:${seconds}`;
            if (distance <= 0 && !alertShown) {
              // Check if the alert hasn't been shown yet

              alertShown = true; // Set the flag to true
              clearInterval(timer);
              if (
                !jwtTokenManager.getToken() ||
                jwtTokenManager.getToken() == null
              ) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.ERROR.TIME_ERROR),
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  heightAuto: false,
                }).then(() => {
                  hideModal(createAPIKeyModalRef.value);
                  router.push({ name: "login" });
                  window.location.reload();
                });
              }
            }
          }, 900);
        }
      } else {
        clearInterval(timer);
      }
    };

    onMounted(async () => {
      token = route.query.token?.toString();
    });

    return {
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
      verifyOTPModel,
      countdownTimer,
      checkOTP,
      submitting
    };
  },
});
</script>
